export default [
    {
        name: 'nodejs',
        label: 'NodeJS Server'
    },
    {
        name: 'browser',
        label: 'Frontend'
    },
    {
        name: 'custom',
        label: 'Custom Adapter'
    }
];